import React, { useState } from "react";
import QRCode from "qrcode";

import { Font, StyleSheet } from "@react-pdf/renderer";
import { Document, Page, Text, View, Image } from "@react-pdf/renderer";

import interRegular from "../../../assets/fonts/Inter-Regular.ttf";
import interBold from "../../../assets/fonts/Inter-Bold.ttf";
import interBlack from "../../../assets/fonts/Inter-Black.ttf";

import chunk from "lodash/chunk";
import { IIssue } from "types/entities";
import { statusNameMap } from "const/utilityEnums";

interface PDFDocumentProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  customRef?: React.MutableRefObject<HTMLDivElement | null>;
  classNames?: {
    className?: string;
    container?: string;
    input?: string;
  };
  paramsForDisplay?: {
    showInternalDescription?: boolean;
    showPrice?: boolean;
  };
  issues: IIssue[];
  organizationName: string | undefined;
  propertyName: string | undefined;
  organizationLogo: string | undefined;
  email: string | undefined;
  phone: string;
  totalSum: number | undefined;
  privateDesc?: boolean | undefined;
  priceToggle?: boolean | undefined;
  dynamicLink?: string | undefined;
}

// Font.register({
//   family: "Ubuntu",
//   fonts: [
//     {
//       src: interRegular,
//     },
//     {
//       src: interBold,
//       fontWeight: "bold",
//     },
//     {
//       src: interBlack,
//       fontWeight: "normal",
//       fontStyle: "italic",
//     },
//   ],
// });

const styles = StyleSheet.create({
  table: {
    width: "100%",
    //fontFamily: "Ubuntu",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  row: {
    display: "flex",
    flexDirection: "row",

    width: "468px",
  },
  issueCard: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    gap: "8px",
    width: "100%",

    padding: "8px",
    paddingBottom: "0px",
    height: "100%",
    borderTop: "1px solid #D4D4D4",
    borderLeft: "1px solid #D4D4D4",
    borderRight: "1px solid #D4D4D4",
    borderBottom: "1px solid #D4D4D4",
  },
  issueCardTopContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: "8px",
  },
  total: {
    display: "flex",
    flexDirection: "row",
    padding: "8px",
    width: "468px",
    height: "37px",
    alignItems: "center",
    gap: "12px",
    color: "#000000",
    border: "1px solid #D4D4D4",
  },
  total_textBlock: {
    width: "125px",
    textAlign: "left",
    //fontFamily: "Ubuntu",
    fontSize: "14px",
    color: "#000000",
  },
  total_sum: {
    width: "100%",
    textAlign: "left",
    //fontFamily: "Ubuntu",
    fontSize: "12px",
    fontWeight: 700,
    color: "#000000",
  },

  headerContainer: {
    display: "flex",
    flexDirection: "column",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",

    borderTop: "none",

    width: "100%",
  },
  qrCode: {
    alignSelf: "center",
    marginLeft: "auto",
    width: "70px",
    height: "70px",
  },
  qrImage: {
    alignSelf: "center",
    marginLeft: "auto",
    width: "65px",
    height: "65px",
  },
  pageNumber: {
    alignItems: "flex-start",
  },
  logoHeader: {
    display: "flex",
    flexDirection: "column",
    padding: "1px",
    justifyContent: "flex-start",
    height: "32px",
    objectFit: "contain",
    maxWidth: "100%",
    alignItems: "center",
  },
  logo: {
    display: "flex",
    flexDirection: "column",
    padding: "1px",
    justifyContent: "flex-start",
    height: "16px",
    objectFit: "contain",
    maxWidth: "80%",
    alignItems: "center",
  },
  headerTextBlock: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "4px",
  },

  headerTextBlock_organizationName: {
    //fontFamily: "Ubuntu",
    fontWeight: 500,
    fontSize: "14px",
    textAlign: "center",
    lineHeight: "125%",
    color: "#1A1C19",
  },
  headerTextBlock_secondaryText: {
    //fontFamily: "Ubuntu",
    fontWeight: 500,
    fontSize: "10px",
    textAlign: "left",
    lineHeight: "125%",
    maxWidth: "300px",
    textOverflow: "ellipsis",
    color: "#1A1C19",
  },
  orderName: {
    //fontFamily: "Ubuntu",
    fontSize: "16px",

    fontWeight: "black",
  },
  pageStyle: {
    //fontFamily: "Ubuntu",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",

    alignItems: "center",
    fontSize: "10px",
    color: "#1A1C19",
    lineHeight: "125%",
  },

  cardImageContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minWidth: "100px",
    width: "100px",
    height: "100px",
    backgroundColor: "gray",
  },

  cardDescriptionContainer: {
    //fontFamily: "Ubuntu",
    display: "flex",
    width: "100%",
    flexDirection: "column",
    textAlign: "center",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    gap: "4px",
  },
  cardTitle: {
    //fontFamily: "Ubuntu",
    maxWidth: "320px",
    height: "21px",
    maxHeight: "42px",
    textAlign: "left",
    //@ts-ignore

    //@ts-ignore
    display: "-webkit-box",
    lineClamp: 2,
    "line-clamp": 2,
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",

    fontSize: "14px",
  },
  cardSubtitleContainer: {
    //fontFamily: "Ubuntu",
    display: "flex",
    flexDirection: "row",
    gap: "8px",
    textAlign: "left",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  cardSubTitleText: {
    //fontFamily: "Ubuntu",

    //@ts-ignore
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    lineHeight: "150%",
    fontSize: "10px",
  },
  cardSubTitleStatus: {
    //fontFamily: "Ubuntu",
    display: "flex",
    //@ts-ignore
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
    lineHeight: 0,
    fontSize: "10px",

    padding: "2px",
    paddingTop: "3px",
    paddingLeft: "3px",
    backgroundColor: "#D4D4D433",
    border: "1px solid #D4D4D4",
  },
  cardPrice: {
    //fontFamily: "Ubuntu",
    maxWidth: "140px",
    fontWeight: 700,
    //@ts-ignore
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    lineHeight: "150%",
    fontSize: "12px",
  },
  cardDescription: {
    display: "flex",
    //fontFamily: "Ubuntu",
    textAlign: "left",
    fontSize: "10px",

    //@ts-ignore

    lineHeight: "1.5px",
  },
  cardDescriptionLabel: {
    display: "flex",
    //fontFamily: "Ubuntu",
    textAlign: "left",
    fontSize: "10px",
    fontWeight: 700,
    //@ts-ignore

    lineHeight: "1.5px",
  },

  footer: {
    position: "absolute",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    bottom: 40,
    left: 76,
    textAlign: "center",
  },
});

export function PDFSequence({
  customRef,
  classNames,
  issues,
  paramsForDisplay = { showInternalDescription: true, showPrice: true },
  organizationName = "Organization Name",
  propertyName = "Property Name",
  organizationLogo,
  totalSum = 0,
  email = "Email",
  phone = "Phone",
  privateDesc,
  priceToggle,
  dynamicLink,

  ...rest
}: PDFDocumentProps) {
  let USDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  const [qrBase64, setQrBase64] = useState("");

  if (dynamicLink && !qrBase64) {
    QRCode.toDataURL(dynamicLink, {
      errorCorrectionLevel: "H",
      type: "image/png",
    })
      .then((url) => {
        setQrBase64(url);
      })
      .catch((err) => {
        console.error("Error generating QR code:", err);
      });
  }

  return (
    <Document
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Page
        size="A4"
        style={{
          position: "relative",
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignContent: "flex-start",
          justifyContent: "flex-start",
          gap: "18px",
          padding: "72px",
          paddingBottom: "62px",
        }}
        wrap
      >
        <View style={styles.headerContainer}>
          <View style={styles.header}>
            <View style={styles.headerTextBlock}>
              {organizationLogo && (
                <View>
                  <Image style={styles.logoHeader} src={organizationLogo} />
                </View>
              )}
              <Text style={styles.headerTextBlock_organizationName}>
                {organizationName || "No organization name"}
              </Text>
              <Text style={styles.headerTextBlock_secondaryText}>
                {propertyName || "No organization name"}
              </Text>
              <Text style={styles.headerTextBlock_secondaryText}>{email}</Text>
            </View>
            <View style={styles.qrCode}>
              {qrBase64 && <Image style={styles.qrImage} src={qrBase64} />}
            </View>
          </View>
        </View>

        <View style={styles.table}>
          {chunk(issues, 1).map((cards, index) => {
            return cards !== null ? (
              <View key={index} style={styles.row}>
                {cards.map((card, i) => {
                  const smallImage =
                    card.child_issues.length > 0
                      ? card.small_photo_url || card.child_issues[0].photo_url
                      : card.showAnnotated
                      ? card.uris?.find(
                          (uri) => uri.type === "thumbnail_annotation"
                        )?.uri
                      : card.uris?.find((uri) => uri.type === "thumbnail")?.uri;
                  const bigImage =
                    card.child_issues.length > 0
                      ? card.photo_url || card.child_issues[0].photo_url
                      : card.showAnnotated
                      ? card.uris?.find((uri) => uri.type === "web_annotation")
                          ?.uri
                      : card.uris?.find((uri) => uri.type === "web")?.uri;

                  const safetyHazardValue =
                    typeof card.hazzard === "boolean"
                      ? card.hazzard
                      : card.hazzard?.toLowerCase() === "true"
                      ? true
                      : false;
                  const recommendedValue =
                    typeof card.recommend === "boolean"
                      ? card.recommend
                      : card.recommend?.toLowerCase() === "true"
                      ? true
                      : false;

                  return (
                    <View key={i} style={styles.issueCard}>
                      <View style={styles.issueCardTopContainer} wrap={false}>
                        <View style={styles.cardImageContainer}>
                          <>
                            <Image
                              style={{
                                width: "100px",
                                height: "100px",
                                objectFit: "contain",
                              }}
                              src={smallImage || bigImage}
                            />
                          </>
                        </View>
                        <View style={styles.cardDescriptionContainer}>
                          <View style={styles.cardSubtitleContainer}>
                            <Text style={styles.cardSubTitleStatus}>
                              {`${statusNameMap[card.status]}`}
                            </Text>
                            <Text
                              style={styles.cardSubTitleText}
                            >{`ID: ${card.id}`}</Text>
                          </View>
                          <Text style={styles.cardTitle}>
                            {card.label || "No title"}
                          </Text>

                          {paramsForDisplay.showPrice && (
                            <Text style={styles.cardPrice}>
                              {USDollar.format(card.price)}
                            </Text>
                          )}
                          <View
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "4px",
                              alignItems: "center",
                              justifyContent: "flex-start",
                            }}
                          >
                            {card.category.toLowerCase() !==
                              "uncategorized" && (
                              <Text style={styles.cardSubTitleStatus}>
                                {`${card.category.toUpperCase()}`}
                              </Text>
                            )}
                            {safetyHazardValue && (
                              <Text style={styles.cardSubTitleStatus}>
                                {`SAFETY HAZARD`}
                              </Text>
                            )}
                            {recommendedValue && (
                              <Text style={styles.cardSubTitleStatus}>
                                {`RECOMMENDED`}
                              </Text>
                            )}
                          </View>
                        </View>
                      </View>
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "2px",
                        }}
                      >
                        <Text
                          // hyphenationCallback={(word) => [word]}

                          style={styles.cardDescription}
                        >
                          <Text style={styles.cardDescriptionLabel}>
                            Description:
                          </Text>{" "}
                          {card.description || "No description"}
                        </Text>
                      </View>

                      {paramsForDisplay.showInternalDescription && (
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "2px",
                          }}
                        >
                          <Text
                            // hyphenationCallback={(word) => [word]}

                            style={styles.cardDescription}
                          >
                            <Text style={styles.cardDescriptionLabel}>
                              Private description:
                            </Text>{" "}
                            {card.description_int || "No private description"}
                          </Text>
                        </View>
                      )}

                      <View
                        style={{
                          height: "1px",
                          paddingTop: "4px",
                          paddingBottom: "4px",
                          marginTop: "4px",
                          marginBottom: "4px",
                        }}
                      />
                    </View>
                  );
                })}
              </View>
            ) : null;
          })}
          {paramsForDisplay.showPrice && (
            <View style={styles.total} wrap={false}>
              <Text style={styles.total_textBlock}>Total:</Text>
              <Text style={styles.total_sum}>{USDollar.format(totalSum)}</Text>
            </View>
          )}
        </View>

        <View fixed style={styles.footer}>
          <View>
            {organizationLogo && (
              <Image style={styles.logo} src={organizationLogo} />
            )}
          </View>
          <View style={styles.pageStyle}>
            <Text
              style={styles.pageNumber}
              render={({ pageNumber }) => `Page ${pageNumber}`}
            />
          </View>
        </View>
      </Page>
    </Document>
  );
}

export default PDFSequence;
