import {
  IUpdatetedInvitedUserResponse,
  useCreateProperty,
  useInviteCompanyUsers,
  useUpdateProperty,
} from "api/query-hooks";
import { inviteCompanyUsers } from "api/requests";
import { FormikHelpers } from "formik";
import { useAuth } from "hooks/authContext";
import { Dispatch, SetStateAction, useCallback, useMemo } from "react";

import { useQueryClient } from "@tanstack/react-query";
import { FIELD_NAMES, IValues } from "./CreatePropertyForm.constants";
import { useNavigate } from "react-router";
import { IGeocoderResult } from "types/googleApiTypes";
import geocodeFormatter from "utilities/common";
import { vanillaJsOriginURL } from "const/urls";
import { useSegment } from "hooks/utility/useSegment";
import { IPropertyItem } from "types/entities";

export function useInitialState(propertyData: any, props: IGeocoderResult) {
  const normalizedResults = geocodeFormatter(props?.properties);
  return useMemo(() => {
    return {
      [FIELD_NAMES.PROPERTY_NAME]: propertyData?.name || "",
      [FIELD_NAMES.COUNTRY]:
        normalizedResults.country || propertyData?.address?.country || "",
      [FIELD_NAMES.ADDRESS_LINE_1]:
        normalizedResults.address1 ||
        `${propertyData.address.street} ${propertyData?.address?.street_number}` ||
        "",
      [FIELD_NAMES.ADDRESS_LINE_2]:
        normalizedResults.address2 ||
        propertyData?.address?.address_line_2 ||
        "",
      [FIELD_NAMES.CITY]:
        normalizedResults.city || propertyData?.address?.city || "",
      [FIELD_NAMES.STATE_REGION]:
        normalizedResults.state || propertyData?.address?.state || "",
      [FIELD_NAMES.POSTAL_CODE]:
        normalizedResults.zip || propertyData?.address?.postal_code || "",
    };
  }, [
    normalizedResults.address1,
    normalizedResults.address2,
    normalizedResults.city,
    normalizedResults.country,
    normalizedResults.state,
    normalizedResults.zip,
    propertyData?.address?.address_line_2,
    propertyData?.address?.city,
    propertyData?.address?.country,
    propertyData?.address?.postal_code,
    propertyData?.address?.state,
    propertyData?.address?.street,
    propertyData?.address?.street_number,
    propertyData?.name,
  ]);
}

export function useSubmitHandler(
  mode: "create" | "edit",

  geocoderProps: IGeocoderResult,
  setIsSubmitting: Dispatch<SetStateAction<boolean>>,
  propertyData?: IPropertyItem
) {
  const { mutateAsync } = useCreateProperty();
  const context = useAuth();
  const { mutateAsync: updateProperty } = useUpdateProperty({
    companyId: context?.companyInfo?.id || "",
  });
  const isGuest = context?.guest;
  const normalizedResults = geocodeFormatter(geocoderProps?.properties);
  const { propertyCreatedCallback } = useSegment();
  return useCallback(
    async (props: IValues, formikHelpers: FormikHelpers<IValues>) => {
      const dataObject = {
        companyId: context?.companyInfo?.id,
        address: {
          city: props.city,
          country: props.country,
          county: normalizedResults.county,
          neighborhood: normalizedResults.neighborhood,
          postal_code: props.postalCode,
          street: normalizedResults.street,
          street_number: normalizedResults.streetNumber,
          subpremise: normalizedResults.subPremise,
          state: normalizedResults.state,
        },
        coordinates: {
          lon: geocoderProps.center.lng,
          lat: geocoderProps.center.lat,
        },
        name: props.propertyName || geocoderProps.name,
        address_line_2: props.addressLine2,
      };
      try {
        formikHelpers.setSubmitting(true);
        setIsSubmitting(true);
        if (mode === "create") {
          const result = await mutateAsync(dataObject);
          if (result.status === 200 || result.status === 201) {
            const segmentData = {
              companyId: context?.companyInfo?.id,
              propertyId: result.data.projectId,
              propertyName: props.propertyName,
            };
            await propertyCreatedCallback(segmentData);
            setTimeout(() => {
              formikHelpers.setSubmitting(false);
              setIsSubmitting(false);
              window.location.replace(
                vanillaJsOriginURL +
                  `/issues?projectId=${result.data.projectId}&rcode=${
                    context.refresh
                  }&companyRef=${context?.companyInfo?.id}${
                    isGuest ? "&guest=true" : ""
                  }${
                    result.data.projectId
                      ? `&properties=${result.data.projectId}`
                      : ""
                  }`
              );
            }, 1500);
          }
        }
        if (mode === "edit" && propertyData) {
          const payload = {
            projectId: propertyData.id,
            property_notes: propertyData?.property_notes || "",
            ...dataObject,
          };

          const result = await updateProperty({ data: payload });
          if (result.status === 200 || result.status === 201) {
            const segmentData = {
              companyId: context?.companyInfo?.id,
              propertyId: result.data.id,
              propertyName: props.propertyName,
            };
            await propertyCreatedCallback(segmentData);
            setTimeout(() => {
              formikHelpers.setSubmitting(false);
              setIsSubmitting(false);
              window.location.replace(
                vanillaJsOriginURL +
                  `/issues?projectId=${propertyData.id}&rcode=${
                    context.refresh
                  }&companyRef=${context?.companyInfo?.id}${
                    isGuest ? "&guest=true" : ""
                  }${
                    result.data.projectId
                      ? `&properties=${result.data.projectId}`
                      : ""
                  }`
              );
            }, 1500);
          }
        }
      } catch (error: any) {
        formikHelpers.setErrors(error.response.data);
        formikHelpers.setSubmitting(false);
        setIsSubmitting(false);
      }
    },
    [
      context?.companyInfo?.id,
      context.refresh,
      normalizedResults.county,
      normalizedResults.neighborhood,
      normalizedResults.street,
      normalizedResults.streetNumber,
      normalizedResults.subPremise,
      normalizedResults.state,
      geocoderProps.center.lng,
      geocoderProps.center.lat,
      geocoderProps.name,
      setIsSubmitting,
      mode,
      propertyData,
      mutateAsync,
      propertyCreatedCallback,
      isGuest,
      updateProperty,
    ]
  );
}
