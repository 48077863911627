import classnames from "classnames";
import { twMerge } from "tailwind-merge";
import { ReactComponent as DropdownArrow } from "assets/img/buttons/dropdownArrow.svg";
import DropdownModal from "../Modals/DropdownModal/DropdownModal";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useOnClickOutside } from "hooks/utility/useOnClickOutside";
import { ReactComponent as CloseIcon } from "assets/img/general/close.svg";
import { IDropdownOptions } from "types/utility";
import { useWindowDimensions } from "hooks/utility/useWindowDimensions";
import { useWindowScroll } from "hooks/utility/useWindowScroll";
import { set } from "lodash";
import { SearchBox } from "components/Elementary/SearchBox/SearchBox";

interface IDropdownItemProps
  extends Partial<React.HTMLAttributes<HTMLDivElement>> {
  className?: string;
  icon?: any;
  hasDelimiter?: boolean;
  textStyling?: string;
  disableHover?: boolean;
  isSelected?: boolean;
  customComponent?: any;
  text: string;
}

function DropdownItem({
  className,
  icon,
  text,
  isSelected,
  textStyling = "text-sm",
  customComponent,
  disableHover = false,
  hasDelimiter = false,
  ...rest
}: IDropdownItemProps) {
  const hoverStyle = disableHover ? "" : "hover:bg-gray-200";
  return (
    <>
      {hasDelimiter && (
        <span className={twMerge(classnames("w-full h-[1px] bg-gray-100"))} />
      )}
      <div
        className={twMerge(
          classnames(
            "relative flex flex-row w-full gap-3 py-2 px-4  cursor-pointer",
            {
              "bg-gray-100": isSelected,
            },
            hoverStyle,
            className
          )
        )}
        {...rest}
      >
        {customComponent && customComponent}
        {!customComponent && (
          <>
            {icon}
            <p
              className={twMerge(
                classnames(textStyling, " truncate ", { "ml-2": icon })
              )}
            >
              {text}
            </p>
          </>
        )}
      </div>
    </>
  );
}

enum SIZE {
  xs = "text-75 h-8 py-2 max-w-full   gap-2",
  sm = "text-75 h-8 py-2 max-w-full gap-2",
  base = "text-87 h-8 py-2.5 max-w-full gap-2",
  lg = "text-87 h-8  py-3 max-w-full gap-2",
  xl = "text-100 h-8   py-3.5 max-w-full gap-2",
  wide = "text-100 h-8  py-3   max-w-full gap-2",
}

enum DYNAMIC_SIZE {
  xs = "px-3  ",
  sm = "px-3  ",
  base = "px-4  ",
  lg = "px-6  ",
  xl = "px-7  ",
  wide = "px-8 w-full",
}

enum CONTENT_TYPE {
  default = "rounded-default  ",
  iconOnly = "rounded-full w-[50px] h-auto p-2 ",
}

export interface IDropdownProps
  extends Omit<
    Partial<React.InputHTMLAttributes<HTMLInputElement>>,
    "onChange"
  > {
  className?: string;
  debug?: boolean;
  classNames?: {
    text?: string;
    dropdownArrow?: string;
    dropdownBody?: string;
    dropdownItem?: string;
    mainContainer?: string;
  };
  searchableDropdown?: boolean;
  toggleOpen?: boolean;
  disableDropdownItemsHover?: boolean;
  dynamicInputSize?: boolean;
  selectedOption?: IDropdownOptions;
  defaultOption?: IDropdownOptions;
  listenedDimensionHeight?: number;
  listenedDimensionWidth?: number;
  listenedScroll?: number;
  description?: string;
  closeDropdownOnChange?: boolean;
  label?: string;
  text?: string;
  placeholder?: string;
  setSelectedOption?: React.Dispatch<
    React.SetStateAction<IDropdownOptions | undefined>
  >;
  filterable?: boolean;
  disableHover?: boolean;
  disableFocus?: boolean;
  isErr?: boolean;
  options: IDropdownOptions[];
  contentType?: "default" | "iconOnly";
  sizes?: "xs" | "sm" | "base" | "lg" | "xl" | "wide";
  colorSchema?: "default" | "primary" | "secondary";
  directionX?: "left" | "right" | "center";
  directionY?: "top" | "bottom";
  actionDropdown?: boolean;
  leftIcon?: any;
  onChange?: (selected: IDropdownOptions | undefined) => void;
  portalNode?: HTMLElement | undefined;
  rowData?: any;
}

export function Dropdown({
  className,
  classNames,
  description,
  searchableDropdown,
  listenedDimensionHeight,
  listenedDimensionWidth,
  listenedScroll,
  disableDropdownItemsHover,
  label,
  dynamicInputSize = false,
  options,
  selectedOption,
  setSelectedOption,
  toggleOpen,
  placeholder,
  closeDropdownOnChange = true,
  filterable = false,
  disableHover = false,
  disableFocus = false,
  isErr = false,
  colorSchema = "default",
  contentType = "default",
  sizes = "base",
  directionX = "center",
  directionY = "bottom",
  actionDropdown = false,
  text,
  defaultOption,
  leftIcon,
  children,
  onChange,
  portalNode,
  ...rest
}: IDropdownProps) {
  const refContainer = useRef<HTMLDivElement | null>(null);
  const refDropdown = useRef<HTMLDivElement | null>(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const enableLocalDimensionListeners =
    !listenedDimensionHeight && !listenedDimensionWidth && !listenedScroll;

  const { height, width } = useWindowDimensions(
    enableLocalDimensionListeners && showDropdown
  );
  const scroll = useWindowScroll(enableLocalDimensionListeners && showDropdown);

  const [filteredOptions, setFilteredOptions] =
    useState<IDropdownOptions[]>(options);

  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  const buttonDefaultStyle = ` flex flex-row w-full items-center truncate ${
    isErr
      ? "bg-red-100"
      : colorSchema === "default"
      ? "bg-surface"
      : colorSchema === "primary"
      ? "bg-successModifier"
      : "bg-transparent"
  }   space-between gap-2`;
  const buttonFilterableStyle = filterable ? "" : "cursor-pointer";
  const buttonSizeStyle = `${SIZE[sizes]} ${
    dynamicInputSize ? "" : DYNAMIC_SIZE[sizes]
  }`;
  const buttonHoverStyle = disableHover
    ? ""
    : ` ${
        isErr
          ? "hover:bg-red-200"
          : colorSchema === "default"
          ? "hover:bg-gray-100"
          : colorSchema === "primary"
          ? "hover:bg-green-100"
          : "hover:bg-transparent"
      } `;
  const buttonFocusStyle = disableFocus
    ? "outline-none"
    : "focus:bg-gray-100 focus:outline focus:outline-focusRing ";
  const buttonDisabledStyle =
    "disabled:bg-[#14201708] disabled:cursor-not-allowed disabled:text-white";
  const closeIconStyle = `h-2 w-2 absolute right-0 mr-8 mt-3 ${
    isErr
      ? "fill-errorBase cursor-pointer"
      : rest.disabled
      ? "fill-disabledItem"
      : colorSchema === "default"
      ? "fill-black cursor-pointer"
      : colorSchema === "primary"
      ? "fill-successBase cursor-pointer"
      : "fill-gray-500 cursor-pointer"
  }`;
  const dropDownArrowStyle = `absolute cursor-pointer   h-4 w-4 right-0 mr-3 mt-2 ${
    isErr
      ? "fill-errorBase"
      : rest.disabled
      ? "fill-disabledItem cursor-not-allowed"
      : colorSchema === "default"
      ? "fill-black"
      : colorSchema === "primary"
      ? "fill-successBase"
      : "fill-gray-500"
  } ${classNames?.dropdownArrow}`;
  const buttonContentTypeStyle = CONTENT_TYPE[contentType];
  useOnClickOutside(
    refContainer,
    () => {
      setShowDropdown(false);
    },
    [refDropdown]
  );
  const filterOptions = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange && onChange(options.find((i) => i.label === e.target.value));
      const filtered = options.filter((option) =>
        option.label
          .toLocaleLowerCase()
          .includes(e.target.value.toLocaleLowerCase())
      );

      setFilteredOptions(filtered);
    },
    [onChange, options]
  );
  const clearCallback = useCallback(() => {
    onChange && onChange(undefined);
    setFilteredOptions(options);
    setSelectedOption && setSelectedOption(undefined);
    setShowDropdown(false);
  }, [onChange, options, setSelectedOption]);

  const extraProps = {
    value:
      contentType === "iconOnly"
        ? ""
        : description
        ? `${description}: ${selectedOption?.label || ""}`
        : selectedOption?.label || "",
  };
  // const inputSize = dynamicInputSize
  //   ? !description
  //     ? { size: selectValue?.length }
  //     : { size: Number(selectValue?.length) + Number(description?.length) }
  //   : {};

  const [inputSize, setInputSize] = useState({});
  useEffect(() => {
    if (dynamicInputSize) {
      setInputSize({
        style: !description
          ? { width: `${Number(selectedOption?.label?.length) + 3}ch` }
          : {
              width: `${
                Number(selectedOption?.value?.length) +
                Number(description?.length) +
                3
              }ch`,
            },
      });
    }
  }, [
    selectedOption?.value,
    description,
    dynamicInputSize,
    selectedOption?.label?.length,
  ]);

  const props = { ...rest, ...extraProps, ...inputSize };

  const [searchableDropdownValue, setSearchableDropdownValue] = useState("");

  const filteredDropdownValues = useMemo(() => {
    if (!searchableDropdown || !searchableDropdownValue) return filteredOptions;
    return filteredOptions.filter(
      (val) =>
        val.label
          .toLocaleLowerCase()
          .includes(searchableDropdownValue.toLocaleLowerCase()) ||
        selectedOption?.label.toLocaleLowerCase() ===
          val.label.toLocaleLowerCase()
    );
  }, [
    filteredOptions,
    searchableDropdown,
    searchableDropdownValue,
    selectedOption?.label,
  ]);

  return (
    <div
      ref={refContainer}
      className={twMerge(
        classnames("relative   h-max", classNames?.mainContainer)
      )}
    >
      {label && (
        <label className={twMerge(classnames("prose-baseSmMedium"))}>
          {label}
        </label>
      )}
      <div
        className={twMerge(
          classnames("flex flex-row relative", {
            "items-center justify-center": contentType === "iconOnly",
          })
        )}
      >
        {description && <p className={twMerge(classnames(""))}></p>}
        <input
          placeholder={placeholder}
          readOnly={!filterable}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            if (filteredOptions.length > 0) {
              if (toggleOpen) {
                setShowDropdown((val) => !val);
              } else {
                setShowDropdown(true);
              }
            }
            if (filteredOptions.length > 0 && showDropdown === true) {
              setShowDropdown(false);
            }
          }}
          className={twMerge(
            classnames(
              buttonDefaultStyle,
              buttonSizeStyle,
              buttonHoverStyle,
              buttonFocusStyle,
              buttonFilterableStyle,
              buttonContentTypeStyle,
              className,
              buttonDisabledStyle,
              classNames?.text
            )
          )}
          {...props}
          onChange={filterOptions}
        />
        {contentType === "iconOnly" && (
          <div className="absolute pointer-events-none">{leftIcon}</div>
        )}
        {filterable && selectedOption && (
          <CloseIcon
            className={twMerge(classnames(closeIconStyle))}
            onClick={rest.disabled ? () => {} : clearCallback}
          />
        )}
        {contentType !== "iconOnly" && (
          <DropdownArrow
            onClick={(e) => {
              if (rest.disabled) {
                return;
              }
              e.preventDefault();
              if (filteredOptions.length > 0) {
                setShowDropdown(true);
              }
              if (showDropdown === true) {
                setShowDropdown(false);
              }
            }}
            className={twMerge(classnames(dropDownArrowStyle))}
          />
        )}
      </div>

      {refContainer.current && showDropdown && (
        <DropdownModal
          directionX={directionX}
          directionY={directionY}
          listenedDimensionHeight={listenedDimensionHeight || height}
          listenedDimensionWidth={listenedDimensionWidth || width}
          listenedScroll={listenedScroll || scroll}
          className={twMerge(
            classnames(
              "max-w-[350px] max-h-[500px] overflow-auto block",
              classNames?.dropdownBody
            )
          )}
          parentNode={refContainer.current || undefined}
          show={showDropdown}
          customRef={refDropdown}
          portalNode={portalNode}
        >
          {searchableDropdown && (
            <div className="flex w-full justify-center items-center relative p-4">
              <SearchBox
                containerSize="sm"
                onClearCallback={() => setSearchableDropdownValue("")}
                classNames={{
                  container: "space-x-0   h-[32px]",
                  searchIconStyle: "left-4 top-2",
                  input: " [@media(max-width:500px)]:pl-8 ",
                  closeIconStyle: "h-6",
                }}
                onChange={(e) => {
                  setSearchableDropdownValue(e.target.value);
                }}
                value={searchableDropdownValue}
                aria-disabled={false}
                placeholder="Search"
              />
            </div>
          )}
          {filteredDropdownValues.map((option, i) => {
            return (
              <DropdownItem
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onChange && onChange(option);
                  setSelectedOption && setSelectedOption(option);
                  setFilteredOptions(options);
                  if (closeDropdownOnChange) {
                    setShowDropdown(false);
                  }
                }}
                isSelected={selectedOption?.value === option.value}
                customComponent={option?.component}
                className={twMerge(
                  classnames(
                    classNames?.dropdownItem,
                    option?.customDropdownItemStyle
                  )
                )}
                key={option.value + i}
                disableHover={disableDropdownItemsHover}
                text={option.label}
                icon={option?.icon}
                hasDelimiter={option?.hasDelimiter}
                textStyling={option?.textStyling}
              />
            );
          })}
          {filteredDropdownValues.length === 0 && (
            <div className="flex flex-col py-4">
              <p className="px-4">No values match the search</p>
            </div>
          )}
        </DropdownModal>
      )}
    </div>
  );
}

//TODO - CHANGE
export function CustomDropdownWithState(props: IDropdownProps) {
  const [value, setValue] = useState<IDropdownOptions | undefined>(
    props.selectedOption || undefined
  );

  useEffect(() => {
    setValue(props.selectedOption);
  }, [props.selectedOption]);

  return (
    <Dropdown
      {...props}
      onChange={(selected) => {
        if (selected?.action) {
          selected.action();
        } else {
          setValue(selected);
          if (props.onChange) {
            props.onChange(selected);
          }
        }
      }}
      selectedOption={value || props.selectedOption}
    />
  );
}
